<template>
  <form
    class="flex w-full flex-col items-stretch justify-between p-4 lg:p-8"
    @submit="onSubmit"
  >
    <div class="flex shrink flex-col items-stretch gap-4">
      <button
        class="flex items-center gap-4 text-start text-base font-medium text-black lg:mb-4 lg:text-xl"
        type="button"
        @click="emit('goBack')"
      >
        <IconArrowRight class="size-4 rotate-180 md:hidden lg:size-6" /> Изменить адрес
      </button>
      <div>
        <InputAutocomplete
          v-model:query="query"
          name="address"
          class="z-20"
          label="Город, улица и дом"
          @update:query="upd($event)"
          :options="data || []"
          :is-loading="isLoadingAddressSearch"
          :display-value="(option: any) => option.display_name"
        />
      </div>

      <div class="flex gap-4">
        <InputText
          class="flex-1"
          name="entrance"
          label="Подьезд"
        />
        <InputText
          class="flex-1"
          name="door_phone"
          label="Код двери"
        />
      </div>

      <div class="flex gap-4">
        <InputNumber
          class="flex-1"
          name="floor"
          label="Этаж"
        />
        <InputText
          class="flex-1"
          name="apartment"
          label="Номер квартиры"
        />
      </div>

      <InputTextarea
        name="comment"
        label="Комментарий"
        :rows="3"
        disable-resize
      />
    </div>

    <div>
      <SimpleButton
        class="w-full px-4 py-4 text-sm font-medium uppercase"
        type="submit"
        :disabled="isPendingUpdateAddress"
      >
        Сохранить
      </SimpleButton>
    </div>
  </form>
</template>

<script setup lang="ts">
import * as yup from 'yup'
import type { LngLat } from '@yandex/ymaps3-types'
import type { Address } from '~/interfaces/main'

const props = defineProps<{
  address: Address
  mapCoords: LngLat
}>()

const emit = defineEmits(['updateCoords', 'goBack'])

const axiosPrivate = usePrivateAxiosInstance()

const { handleSubmit, setFieldValue } = useForm<any>({
  validationSchema: yup.object({
    address: yup
      .object({
        lat: yup.string(),
        lon: yup.string(),
          street: yup.string(),
          house: yup.string(),
          city: yup.string(),
        display_name: yup.string(),
      })
      .default(undefined)
      .required()
      .label('Город, улица и дом'),
      entrance: yup.string().label('Подъезд'),
    door_phone: yup.string().label('Код двери'),
    floor: yup.string().label('Этаж'),
      apartment: yup.string().label('Номер квартиры'),
    comment: yup.string().label('Комментарий'),
  }),
  initialValues: computed(() => ({
    address: {
      lat: props.address.latitude,
      lon: props.address.longitude,
      display_name: props.address.full,
        city: props.address.city,
        house: props.address.house,
        street: props.address.street,
    },
      entrance: props.address.entrance,
    door_phone: props.address.door_phone,
    floor: props.address.floor,
      apartment: props.address.apartment,
    comment: props.address.comment,
  })),
})

const address = useFieldValue<{ lat: string; lon: string; display_name: string }>('address')
const coordinates = computed(() => {
  if (address.value) {
    return [Number(address.value.lon), Number(address.value.lat)]
  }
  return [37.617698, 55.755864]
})
const upd = (e) => {
    from_geo = false;
}

let from_geo = false;
watch([() => coordinates.value], () => {
    emit('updateCoords', [coordinates.value, from_geo])
})
//watchEffect(() => emit('updateCoords', coordinates.value))

watch([() => props.mapCoords], () => {
  axiosPrivate
    .get('user/address/search/geo', {
      params: {
        latitude: props.mapCoords[1],
        longitude: props.mapCoords[0],
      },
    })
    .then((res) => {
        from_geo = true;
      setFieldValue('address', res.data)
    })
})

const query = ref('')
const throttledQuery = refThrottled(query, 500, undefined, false)

const { data, isLoading: isLoadingAddressSearch } = useAddressSearch(throttledQuery, (v) => v, true)
const { mutateAsync: updateAddressAsync, isPending: isPendingUpdateAddress } = useUpdateAddress()

const onSubmit = handleSubmit((vals: any) => {
  let fullName = vals.address.display_name
  if (vals.door_phone) fullName += `, код двери ${vals.door_phone}`
  if (vals.apartment) fullName += `, кв. ${vals.apartment}`
  if (vals.entrance) fullName += `, подъезд ${vals.entrance}`

  const body = {
    id: props.address.id,
      entrance: vals.entrance,
    door_phone: vals.door_phone,
    floor: vals.floor,
    house: vals.address.house,
    apartment: vals.apartment,
    city: vals.address.city,
    street: vals.address.street,
    comment: vals.comment,
    latitude: vals.address.lat,
    longitude: vals.address.lon,
    address: vals.address.display_name,
    full_name: fullName,
  }
  updateAddressAsync(body).then(() => emit('goBack'))
})
</script>
