import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import { useToast } from 'vue-toastification'
import type { DishInBasket } from '~/interfaces/main'
import MyToast from '~/components/MyToast.vue'
import { MyToastError } from '#components'

export interface GetBasketResponse {
  items: DishInBasket[]
  total_items: number
  total_price: number
  total_discount: number
  delivery_price: number
  loyalty_program_id: string
  total_sum: number
}


interface LoyaltyProgramDiscount {
  position_id: string,
  discount_sum: number
}
interface LoyaltyProgramFreeProduct {
  iiko_id: string,
  position_id: string
}

export interface LoyaltyProgram {
  id: string,
  name: string,
  free_products: LoyaltyProgramFreeProduct[],
  discount: LoyaltyProgramDiscount[],
  total_discount: number,
  bonuses_available_to_spend: number,
  bonuses_award_sum: number
}

export interface LoyaltyPrograms extends Array<LoyaltyProgram>{}

export const useBasket = <SData>(select: (response: GetBasketResponse) => SData) => {
  const privateAxios = usePrivateAxiosInstance()
  const { userCredentials } = useUserCredentials()

  return useQuery({
    queryKey: ['user', 'basket', 'loyalty_programs'],
    queryFn: async () => {
      const response = await privateAxios.get<GetBasketResponse>('user/cart-items')

      response.data.items = response.data.items || []

      return response.data
    },
    select,
    enabled: userCredentials.value.isAuthenticated,
  })
}

export const useInvalidateBasket = () => {
  const queryClient = useQueryClient()

  return () => {
    queryClient.invalidateQueries({
      queryKey: ['user', 'basket'],
    })
  }
}

interface UseBasketWithGiftsOptions {
  enabled?: MaybeRef<boolean>
  coupon?: MaybeRef<string>
  loyalty_program_id?: MaybeRef<string>
}

export const useLoyaltyPrograms = <SData>(
  select: (response: LoyaltyPrograms) => SData,
  options: UseBasketWithGiftsOptions = {}
) => {

  const { coupon = '', enabled = false } = options
  const privateAxios = usePrivateAxiosInstance()
  const { userCredentials } = useUserCredentials()

  const dataQuery =  useQuery({
    queryKey: ['user', 'basket', 'loyalty_programs', { coupon }] as [string, string, string, { coupon: string }],
    queryFn: async ({ queryKey }) => {
      const response = await privateAxios.get<LoyaltyPrograms>('user/loyalty-programs', {
        params: {
          coupon: queryKey[3].coupon || "",
        },
      })

      return response.data
    },
    select,
    enabled: computed(() => userCredentials.value.isAuthenticated && unref(enabled)),
    placeholderData: (v) => v,
  })

  return dataQuery
}

export const useBasketWithGifts = <SData>(
  select: (response: GetBasketResponse) => SData,
  options: UseBasketWithGiftsOptions = {}
) => {
  const { coupon = '', loyalty_program_id = '', enabled = false } = options
  const privateAxios = usePrivateAxiosInstance()
  const { userCredentials } = useUserCredentials()

  return useQuery({
    queryKey: ['user', 'basket', { coupon, loyalty_program_id }] as [string, string, { coupon: string, loyalty_program_id: string }],
    queryFn: async ({ queryKey }) => {
      const response = await privateAxios.get<GetBasketResponse>('user/cart-items', {
        params: {
          coupon: queryKey[2].coupon || undefined,
          loyalty_program_id: queryKey[2].loyalty_program_id || "",
        },
      })

      response.data.items = response.data.items || []

      return response.data
    },
    select,
    enabled: computed(() => userCredentials.value.isAuthenticated && unref(enabled)),
    placeholderData: (v) => v
  })
}

export const useAddToBasket = () => {
  const privateAxios = usePrivateAxiosInstance()
  const invalidate = useInvalidateBasket()
  const toast = useToast()

  return useMutation({
    mutationFn: async (vars: { id?: number; quantity: number; dish_id: number; dish_name: string }) => {
      const response = await privateAxios.post('user/cart-items', {
        id: vars.id,
        quantity: vars.quantity,
        dish_id: vars.dish_id,
      })
      return response
    },
    onSuccess(_, vars) {
      if (!vars.id) {
        toast({
          component: MyToast,
          props: {
            title: 'Товар добавлен в корзину:',
            detail: vars.dish_name,
          },
        })
      }
      invalidate()
    },
  })
}
